import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(1.5),
    width: 154,
    background: '#636363',
    borderRadius: 4,
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.3)',
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.text.secondary,
    cursor: 'default',
  },
  popper: {
    zIndex: 5,
    '&[x-placement*="top-end"]': {
      '& > #arrow': {
        bottom: 0,
        right: 4,
        left: 'unset !important',
        marginBottom: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '.75em .75em 0 .75em',
          borderColor: '#636363 transparent transparent transparent',
        },
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  tooltipText: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
}));

export default function CombineLabelTooltip({
  labelContent, anchorEl, assetClass,
}) {
  const classes = useStyles({ assetClass });

  const [arrowRef, setArrowRef] = useState(null);
  const openDesktop = Boolean(anchorEl);

  return (
    <>
      <Popper
        className={classes.popper}
        open={openDesktop}
        anchorEl={anchorEl}
        placement="top-end"
        popperOptions={{
          modifiers: {
            offset: {
              offset: '8,4',
            },
          },
        }}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <span className={classes.arrow} ref={setArrowRef} id="arrow" />
        <div className={classes.tooltip}>
          <div className={classes.tooltipText}>
            {labelContent.label}
          </div>
          <div>
            {labelContent.tooltip_text}
          </div>
        </div>
      </Popper>
    </>
  );
}

CombineLabelTooltip.defaultProps = {
  anchorEl: null,
};

CombineLabelTooltip.propTypes = {
  labelContent: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    tooltip_text: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: PropTypes.object,
  assetClass: PropTypes.string.isRequired,
};
