export const futures = 'futures';
export const forex = 'forex';
export const COUNTRIES = {
  AUD: {
    symbol: '$',
    code: 'AU',
    currency: 'AUD',
  },
  CAD: {
    symbol: '$',
    code: 'CA',
    currency: 'CAD',
  },
  COP: {
    symbol: '$',
    code: 'CO',
    currency: 'COP',
  },
  EUR: {
    symbol: '€',
    code: 'EU',
    currency: 'EUR',
  },
  GBP: {
    symbol: '£',
    code: 'GB',
    currency: 'GBP',
  },
  JPY: {
    symbol: '¥',
    code: 'JP',
    currency: 'JPY',
  },
  MXN: {
    symbol: '$',
    code: 'MX',
    currency: 'MXN',
  },
  // RUB: {
  //   symbol: '₽',
  //   code: 'RU',
  //   currency: 'RUB',
  // },
  USD: {
    symbol: '$',
    code: 'US',
    currency: 'USD',
  },
  TWD: {
    symbol: 'NT$',
    code: 'TW',
    currency: 'TWD',
  },
};
export const DOMAINS = {
  FX_APP: 'topstepfx.com',
  FUTURES_APP: 'topsteptrader.com',
  LOCAL: 'localhost',
};
export const breadCrumbs = {
  futures: ['Account Size', 'Trading Platform', 'Review Details', 'Get Started'],
  forex: ['Account Size', 'Review & Payment', 'Get Started'],
};

export const tagManagerArgs = {
  gtmId: 'GTM-59V4Q6N',
};
