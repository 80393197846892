import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CombineLabelTooltipMobile from '../combinesLabelsTooltip/CombineLabelTooltipMobile';
import CombineLabelTooltip from '../combinesLabelsTooltip/CombineLabelTooltip';

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    placeContent: 'flex-end',
    '&.inactive': {
      opacity: 0.3,
    },
  },
  infoIcon: {
    fontSize: 13,
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(0.5),
  },
  text: {
    cursor: 'default',
    letterSpacing: '-0.3px',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '14px',
      '&.interactive': {
        textDecoration: 'underline',
      },
    },
  },
}));

export default function CombinesLabel({
  labelContent, handleHoverLabel, currentHover, assetClass,
}) {
  const classes = useStyles({ assetClass });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    if (!open) {
      setOpen(true);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleInteraction = (event, label) => {
    if (label.tooltip_text !== null) {
      setAnchorEl(event.currentTarget);
      if (!isMobile) {
        handleToggle();
        handleHoverLabel(labelContent.key);
      }
    }
  };

  const handleInteractionClose = () => {
    handleHoverLabel('');
    setAnchorEl(null);
    if (!isMobile) {
      handleClose();
    }
  };

  const hoverActive = currentHover !== '';

  return (
    <>
      <div
        className={[classes.container, labelContent.key !== currentHover && hoverActive ? 'inactive' : ''].join(' ')}
        onClick={handleToggle}
        id={labelContent.key}
        onMouseDown={(e) => { e.preventDefault(); }}
        onMouseEnter={(e) => { handleInteraction(e, labelContent); }}
        onMouseLeave={handleInteractionClose}
        onFocus={(e) => { handleInteraction(e, labelContent); }}
        onBlur={handleInteractionClose}
        onKeyDown={handleInteractionClose}
        tabIndex={0}
        role="button"
      >
        <Typography
          variant="body2"
          color="textPrimary"
          align="right"
          className={[classes.text, labelContent.tooltip_text !== null ? 'interactive' : ''].join(' ')}
        >
          {labelContent.label}
        </Typography>
        {labelContent.tooltip_text !== null && (
          <>
            {!isMobile && (
            <>
              <InfoOutlinedIcon
                className={classes.infoIcon}
              />
              <CombineLabelTooltip labelContent={labelContent} assetClass={assetClass} anchorEl={anchorEl} />
            </>
            )}

          </>
        )}
      </div>
      {isMobile && (
      <CombineLabelTooltipMobile
        text={labelContent.tooltip_text}
        label={labelContent.label}
        assetClass={assetClass}
        open={open}
        handleClose={handleClose}
      />
      )}
    </>
  );
}

CombinesLabel.propTypes = {
  labelContent: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    tooltip_text: PropTypes.string,
  }).isRequired,
  handleHoverLabel: PropTypes.func.isRequired,
  currentHover: PropTypes.string.isRequired,
  assetClass: PropTypes.string.isRequired,
};
