import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import CombinesLabels from '../combinesLabels/CombinesLabels';
import ScrollStepper from '../scrollStepper/ScrollStepper';
import CurrencyConverter from '../converter/CurrencyConverter';
import useContainerDimensions from '../../helpers/UseContainerDimensions';
import Combine from '../combine/Combine';
import config from '../../config';
import { usePausePurchasesFlag } from '../../utils/feature-flag-v2-utils';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0, 0, 1),
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      padding: theme.spacing(0, 0, 0, 1),
    },
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  detailsContainer: {
    paddingRight: theme.spacing(1),
    minWidth: 78,
    maxWidth: 140,
    width: '12vw',
    display: 'flex',
    placeItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  combinesContainer: {
    display: 'flex',
    overflowY: 'scroll',
    maxWidth: '100%',
    scrollSnapType: 'x mandatory',
    position: 'relative',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  converterMobile: {
    opacity: ({ currencyError }) => (currencyError ? 0 : 1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(11),
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      paddingBottom: theme.spacing(11),
    },
    width: 'calc(100% - 8px)',
  },
  combinesInfoContainer: {
    overflow: 'auto',
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'black',
    opacity: 0.3,
    width: '100vw',
    height: '100vh',
    zIndex: 3,
  },
  approxContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  approxLabel: {
    fontSize: 12,
    letterSpacing: 0,
    fontWeight: 'bold',
  },
  link: {
    marginLeft: theme.spacing(0.5),
    textDecoration: 'underline',
    color: '#306bb9',
  },
}));

export default function Combines({
  assetClass, data, selectedCurrency, handleCurrencyChange, currencyError, setCurrencyError,
}) {
  const classes = useStyles({ assetClass, currencyError });
  const dataFiltered = data.assets.find((element) => element.name.toLowerCase() === assetClass);
  const padding = 8;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Handle resizing on window resize
  const combinesRef = useRef();
  const labelsRef = useRef();

  const combineWidth = useContainerDimensions(combinesRef, assetClass).widthChild || 187;
  const labelsWidth = useContainerDimensions(labelsRef, assetClass).width;
  const { widthParent } = useContainerDimensions(labelsRef, assetClass);
  // Account for extra padding
  const availableWidth = widthParent - labelsWidth + padding;

  const [currentHoverLabel, setCurrentHoverLabel] = useState('');
  const handleHoverLabel = (newValue) => {
    setCurrentHoverLabel(newValue);
  };

  // Handle hovering over combine
  const [currentHover, setCurrentHover] = useState('');
  const handleHover = (newValue) => {
    setCurrentHover(newValue);
  };

  // Handle scrolling between combines
  const [scrollPos, setScrollPos] = useState(0);
  const handleScroll = () => {
    // onScroll fires often so set a timeout
    setTimeout(() => {
      setScrollPos(combinesRef.current.scrollLeft);
    }, 200);
  };
  // Account for padding of parent container
  const currentPosition = scrollPos > 8 ? Math.ceil((scrollPos - padding) / combineWidth) : 0;

  // On tab switch, reset position
  useEffect(() => {
    setScrollPos(0);
    combinesRef.current.scrollLeft = 0;
  }, [assetClass]);

  // On tab switch, reset position
  useEffect(() => {
    handleHover('');
  }, [currentPosition]);

  // calculate how many combines can fit into view
  const howManyFit = Math.floor(availableWidth / combineWidth);
  const dotsLength = Math.ceil(dataFiltered.combine_sizes.length - howManyFit) + 1;

  const [open, setOpen] = React.useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  // check if smallest asset class fits on page, if it does dont show the scroll stepper for either
  const smallerDataset = data.assets.reduce((a, b) => (a.combine_sizes.length < b.combine_sizes.length ? a : b));
  const smallestDotLength = Math.ceil(smallerDataset.combine_sizes.length - howManyFit) + 1;
  const pausePurchasesFlag = usePausePurchasesFlag();
  return (
    <Container className={classes.container}>
      {smallestDotLength > 0 && (
      <ScrollStepper dotsLength={dotsLength} position={currentPosition} />
      )}

      <div className={classes.root}>
        {/* Labels */}
        <div className={classes.detailsContainer} ref={labelsRef}>
          {isMobile && (
          <div className={classes.converterMobile}>
            {open && (<div className={classes.backdrop} />) }
            <CurrencyConverter selectedCurrencyCallback={handleCurrencyChange} handleToggle={handleToggle} setCurrencyError={setCurrencyError} />
          </div>
          )}
          <CombinesLabels
            labels={dataFiltered.attribute_labels}
            handleHoverLabel={handleHoverLabel}
            currentHover={currentHoverLabel}
            assetClass={assetClass}
          />
        </div>

        <div className={classes.combinesInfoContainer}>
          <div className={classes.combinesContainer} ref={combinesRef} onScrollCapture={() => { handleScroll(); }} id={assetClass}>
            {/* Combines */}
            <Combine
              data={dataFiltered}
              assetClass={assetClass}
              selectedCurrency={selectedCurrency}
              combinesRef={combinesRef}
              currentPosition={currentPosition}
              howManyFit={howManyFit}
              combineWidth={combineWidth}
              currentHoverLabel={currentHoverLabel}
              handleHover={handleHover}
              currentHover={currentHover}
            />
          </div>
        </div>
      </div>
      <div className={classes.approxContainer}>
        {selectedCurrency && selectedCurrency.currency !== 'USD' && (
        <>
          <Typography variant="caption" color="inherit" className={classes.approxLabel}>
            *Approximate Currency Conversion
          </Typography>
        </>
        )}
      </div>
      <div className={classes.approxContainer}>
        {pausePurchasesFlag && (
        <>
          <Typography variant="body1" color="textPrimary" className={classes.largerFont}>
            <Link href={config.STATUS_PAGE} className={classes.link}>Click here for our Status Page</Link>
          </Typography>
        </>
        )}
      </div>
    </Container>
  );
}

Combines.defaultProps = {
  selectedCurrency: null,
};

Combines.propTypes = {
  assetClass: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  selectedCurrency: PropTypes.shape({
    symbol: PropTypes.string,
    code: PropTypes.string,
    rate: PropTypes.number,
    currency: PropTypes.string,
  }),
  handleCurrencyChange: PropTypes.func.isRequired,
  currencyError: PropTypes.bool.isRequired,
  setCurrencyError: PropTypes.func.isRequired,
};
