import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Main from './components/main/Main';
import { useTestFeatureFlag } from './utils/feature-flag-v2-utils';

function App() {
  console.log('Feature Flag initialized.', {
    test: useTestFeatureFlag(),
  });
  return (
    <Router>
      <Route path="/" component={Main} />
    </Router>
  );
}

export default App;
