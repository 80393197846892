import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CombinesLabel from './CombinesLabel';

const useStyles = makeStyles((theme) => ({
  li: {
    height: 51,
    display: 'flex',
    alignItems: 'center',
    placeContent: 'flex-end',
  },
  combineDetails: {
    paddingBottom: theme.spacing(10.37),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(1.8),
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      paddingBottom: theme.spacing(1.8),
    },
  },
}));

export default function CombinesLabels({
  labels, handleHoverLabel, currentHover, assetClass,
}) {
  const classes = useStyles();

  return (
    <div className={classes.combineDetails}>
      {labels.map((label) => (
        <div className={classes.li} key={label.key}>
          <CombinesLabel
            currentHover={currentHover}
            handleHoverLabel={handleHoverLabel}
            labelContent={label}
            assetClass={assetClass}
          />
        </div>
      ))}
    </div>
  );
}

CombinesLabels.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    tooltip_text: PropTypes.string,
  })).isRequired,
  handleHoverLabel: PropTypes.func.isRequired,
  currentHover: PropTypes.string.isRequired,
  assetClass: PropTypes.string.isRequired,
};
