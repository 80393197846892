import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { breadCrumbs } from '../../constants';
import { ReactComponent as Arrow } from '../../assets/largeArrow.svg';
import { ReactComponent as Logo } from '../../assets/WhiteT.svg';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    background: theme.palette.primary.main,
    height: 60,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      height: 40,
    },
    [theme.breakpoints.down('xs')]: {
      height: 40,
    },
  },
  breadcrumbsContainer: {
    paddingLeft: theme.spacing(3),
    color: '#808080',
    flexGrow: 1,
  },
  active: {
    color: '#ffffff',
  },
  inactive: {
    color: '#808080',
  },
  closeIcon: {
    marginRight: theme.spacing(3),
    cursor: 'pointer',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 0,
    },
  },
  logo: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    height: '55%',
  },
  headerContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}));

const backToPreviousPage = (assetClass) => {
  const previousUrl = document.referrer;
  let redirectUrl;
  // set session cookie if they leave so we can drop them back on the correct app tab
  document.cookie = `asset_class=${assetClass}`;

  switch (true) {
    case previousUrl.includes(config.FUTURES_CMS_URL):
      redirectUrl = previousUrl;
      break;
    case previousUrl.includes(config.FUTURES_CTA_URL): // using a redirect through the futures app, so the previous url would create a loop
      redirectUrl = config.FUTURES_CTA_URL;
      break;
    default:
      redirectUrl = config.FUTURES_CMS_URL;
      break;
  }
  return window.location.assign(redirectUrl);
};

export default function Header({ assetClass }) {
  const classes = useStyles({ assetClass });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const isMobileLandscape = (isTablet && isLandscape);

  return (
    <div className={classes.headerContainer}>
      <div className={classes.logo}>
        <Logo />
      </div>
      {!isMobile && !(isMobileLandscape) && (
      <>
        <Arrow />
        <div className={classes.headerContent}>
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            className={classes.breadcrumbsContainer}
          >
            {breadCrumbs[assetClass].map((crumb, index) => {
              // first element is active
              const cls = (index === 0) ? classes.active : classes.inactive;
              return (
                <Typography color="inherit" variant="button" className={cls} key={crumb}>{crumb}</Typography>
              );
            })}
          </Breadcrumbs>
        </div>
      </>
      )}
      <CloseIcon
        color="inherit"
        className={classes.closeIcon}
        onClick={() => { backToPreviousPage(assetClass); }}
        tabIndex={0}
        onMouseDown={(e) => { e.preventDefault(); }}
        onKeyDown={(e) => {
          const code = e.keyCode;
          if (code === 13) {
            backToPreviousPage(assetClass);
          }
        }}
      />
    </div>
  );
}

Header.propTypes = {
  assetClass: PropTypes.string.isRequired,
};
