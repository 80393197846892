import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from 'react-router';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import AppTabs from '../appTabs/AppTabs';
import { Theme } from '../../Theme';
import Combines from '../combines/Combines';
import config from '../../config';
import backupData from '../../json/CombinesDataBackup.json';
import { futures } from '../../constants';
import CurrencyConverter from '../converter/CurrencyConverter';
import Header from '../header/Header';
import BWCandlestick from '../../assets/B&WCandlestick.png';

const useStyles = makeStyles((theme) => ({
  app: {
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: `url(${BWCandlestick})`,
    backgroundSize: 'cover',
    backgroundColor: 'black',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 3),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 6),
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(0, 9),
    },
    minHeight: '100vh',
    alignItems: 'center',
  },
  main: {
    width: '100%',
    maxWidth: 1296,
    maxHeight: '100vh',
    height: 740,
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
    },
    background: '#fff',
  },
  mainHeader: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
  },
  converterDesktop: {
    position: 'absolute',
    right: 50,
    opacity: ({ currencyError }) => (currencyError ? 0 : 1),
  },
  loading: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  discountContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

function checkQueryParams(params) {
  return new URLSearchParams(params);
}

function hasAssetCookie() {
  const assetCookieString = document.cookie.split(';').filter((item) => item.trim().startsWith('asset_class='))[0];
  return assetCookieString && assetCookieString.split('=')[1];
}

function getInitialState(params) {
  // Get initial state from params, if none exists or if invalid, default to futures
  const queryParameter = checkQueryParams(params).get('asset_class');
  const initialAssetClass = queryParameter || hasAssetCookie() || futures;
  const correctParameterExists = initialAssetClass === futures;
  return correctParameterExists ? initialAssetClass : futures;
}

export default function Main() {
  const location = useLocation();
  const [items, setItems] = useState(backupData);
  const [isLoaded, setIsLoaded] = useState(false);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const [assetClass, setAssetClass] = useState(getInitialState(location.search));

  const handleChange = (newValue) => {
    setAssetClass(newValue);
  };

  const [currency, setCurrency] = useState(null);
  const [currencyError, setCurrencyError] = useState(false);
  const classes = useStyles({ currencyError });

  const handleCurrencyChange = (incomingCurrency) => {
    setCurrency(incomingCurrency);
  };

  useEffect(() => {
    axios.get(config.API_URL)
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.data);
        },
        () => {
          setIsLoaded(true);
        },
      );
  }, []);

  return (
    <Theme>
      <div className={classes.app}>
        <div className={classes.main}>
          <Header assetClass={assetClass} />
          {(!isLoaded) ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className={classes.mainHeader}>
                <AppTabs assetClass={assetClass} handleChange={handleChange} />
                {!isMobileView && (
                  <div className={classes.converterDesktop}>
                    <CurrencyConverter selectedCurrencyCallback={handleCurrencyChange} setCurrencyError={setCurrencyError} />
                  </div>
                )}
              </div>
              <div className={classes.discountContainer}>
                <Typography variant="body1" color="textPrimary">
                  Any discounts will be applied at checkout.
                </Typography>
              </div>
              <Combines
                assetClass={assetClass}
                data={items}
                selectedCurrency={currency}
                handleCurrencyChange={handleCurrencyChange}
                currencyError={currencyError}
                setCurrencyError={setCurrencyError}
              />
            </>
          )}
        </div>
      </div>
    </Theme>
  );
}
