import { useLDClient } from 'launchdarkly-react-client-sdk';
import config from '../config';

const { ENABLED, CLIENT_SIDE_ID } = config.LAUNCHDARKLY;

const DEFAULT_USER = {
  key: 'default-user',
  name: 'Default User',
};

const featureFlagKey = {
  // PAUSE NEW TC
  pause_purchases: 'pause-purchases', // Used to disabled to Select button on Account Size
  // Test feature flags.
  TEST: 'test', // Used for testing purposes.
};

const DEFAULT_KEY_VALUES = {
  // Live feature flags.
  [featureFlagKey.SC_30153]: true,
  // Live feature flags.
  [featureFlagKey.pause_purchases]: true,
  // Test feature flags.
  [featureFlagKey.TEST]: false,
};

/**
 * Holds LaunchDarkly Application Details.
 */
const APPLICATION = {
  id: 'topstep-futures-purchase-journey-react',
  version: '1.0.0',
};

/**
 * Determine if LaunchDarkly should be skipped.
 */
const shouldSkip = () => (!ENABLED || !CLIENT_SIDE_ID);

/**
 * Transform User to LDUser.
 */
const transformUserToLDUser = (user, admin = false) => ({
  key: `${admin ? 'admin-id-' : 'user-id-'}${user.id}`,
  name: `${user.first_name} ${user.last_name}`,
  firstName: user.first_name,
  lastName: user.last_name,
  email: user.email,
});

/**
 * Evaluate LaunchDarkly feature flag variation.
 */
const variation = (key, defaultValue, ldClient) => {
  if (shouldSkip()) {
    return defaultValue;
  }
  return ldClient.variation(key, defaultValue);
};

/**
 * Determine if test is enabled.
 *
 * This is simply a feature flag that is not wired up to any features
 * and is used testing purposes. Feel free to toggle on or off as needed.
 */
const useTestFeatureFlag = () => variation(
  featureFlagKey.TEST,
  DEFAULT_KEY_VALUES[featureFlagKey.TEST],
  useLDClient(),
);

const usePausePurchasesFlag = () => variation(
  featureFlagKey.pause_purchases,
  DEFAULT_KEY_VALUES[featureFlagKey.pause_purchases],
  useLDClient(),
);

export {
  transformUserToLDUser, useTestFeatureFlag, APPLICATION, CLIENT_SIDE_ID, DEFAULT_USER, usePausePurchasesFlag,
};
