import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { futures } from '../../constants';

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0),
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      margin: theme.spacing(2, 0),
    },
  },
  tabs: {
    width: '265px',
    maxWidth: '67vw',
    height: '48px',
    borderRadius: '24px',
    display: 'flex',
    position: 'relative',
  },
  tab: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 2,
    userSelect: 'none',
    marginLeft: 4,
    marginRight: 4,
    textDecoration: 'none',
  },
  activeTab: {
    width: '65%',
    height: '40px',
    background: theme.palette.primary.main,
    borderRadius: '20px',
    position: 'absolute',
    top: 4,
    left: '2.7rem',
  },
}));

export default function AppTabs({ assetClass, handleChange }) {
  const classes = useStyles();

  return (
    <div className={classes.tabContainer}>
      <div
        className={classes.tabs}
        id="asset-toggle"
        tabIndex={0}
        role="button"
      >
        <Typography
          onClick={() => { handleChange(futures); }}
          onMouseDown={(e) => { e.preventDefault(); }}
          className={classes.tab}
          tabIndex={-1}
        >
          <Typography color="textSecondary" variant="button">
            Futures
          </Typography>
        </Typography>
        <div className={[classes.activeTab, assetClass].join(' ')} />
      </div>
    </div>
  );
}

AppTabs.propTypes = {
  assetClass: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};
