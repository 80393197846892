import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CombinesHeader from '../combinesHeader/CombinesHeader';
import CombinesDetails from '../CombinesDetails/CombinesDetails';
import CombinesButton from '../combinesButton/CombinesButton';
import { ReactComponent as PopularTag } from '../../assets/popular.svg';

const useStyles = makeStyles((theme) => ({
  combine: {
    minWidth: 210,
    maxWidth: 230,
    [theme.breakpoints.down('xs')]: {
      minWidth: 115,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      minWidth: 115,
    },
    width: '11vw',
    position: 'relative',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 0px 5px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    '&.hover': {
      boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.14), 0px 0px 10px 4px rgba(0, 0, 0, 0.12), 0px 7px 8px -4px rgba(0, 0, 0, 0.2)',
      transition: 'box-shadow 0.2s ease-in-out',
    },
  },
  combineContainer: {
    position: 'relative',
    padding: theme.spacing(2, 1),
    scrollSnapAlign: 'start',
    '&.inactive': {
      opacity: 0.5,
    },
  },
  popularSVG: {
    position: 'absolute',
    top: 0,
    right: 0,
    fill: theme.palette.primary.main,
  },
}));

export default function Combine({
  assetClass, data, selectedCurrency, currentPosition, howManyFit, combinesRef,
  combineWidth, currentHoverLabel, currentHover, handleHover,
}) {
  const classes = useStyles();

  // Low and high range of how many cards fit into view
  const lowRange = (currentPosition);
  const highRange = (currentPosition + (howManyFit - 1));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const isMobileLandscape = (isTablet && isLandscape);

  return (
    <>
      {data.combine_sizes.map((combine, index) => (
        <div
          className={[classes.combineContainer, lowRange <= (index) && (index) <= highRange ? '' : 'inactive'].join(' ')}
          key={combine.name}
        >
          <div
            className={[classes.combine, currentHover === combine.name ? 'hover' : ''].join(' ')}
            onMouseEnter={() => { handleHover(combine.name); }}
            onMouseLeave={() => { handleHover(''); }}
            onClick={() => {
              // if its out of view
              if (!(lowRange <= (index) && (index) <= highRange)) {
                // eslint-disable-next-line no-param-reassign
                combinesRef.current.scrollLeft = (index) * combineWidth;
              }
            }}
          >
            {combine.is_popular && (
            <PopularTag
              className={classes.popularSVG}
            />
            )}
            <CombinesHeader
              monthly={combine.price}
              monthlyDiscount={combine.price_discount}
              total={combine.name}
              assetClass={assetClass}
              selectedCurrency={selectedCurrency}
            />
            {(isMobile || (isMobileLandscape)) && (
            <CombinesButton
              assetClass={assetClass}
              active={currentHover === combine.name}
              url={combine.checkout_url_path}
              handleHoverCard={handleHover}
              combine={combine.name}
            />
            )}
            <CombinesDetails attributes={combine.attributes} currentHover={currentHoverLabel} />
            {!isMobile && !isMobileLandscape && (
            <CombinesButton
              assetClass={assetClass}
              active={currentHover === combine.name}
              url={combine.checkout_url_path}
              handleHoverCard={handleHover}
              combine={combine.name}
            />
            )}
          </div>
        </div>
      ))}
    </>
  );
}

Combine.defaultProps = {
  selectedCurrency: null,
};

Combine.propTypes = {
  currentPosition: PropTypes.number.isRequired,
  howManyFit: PropTypes.number.isRequired,
  combineWidth: PropTypes.number.isRequired,
  currentHoverLabel: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  combinesRef: PropTypes.object.isRequired,
  assetClass: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  selectedCurrency: PropTypes.shape({
    symbol: PropTypes.string,
    code: PropTypes.string,
    rate: PropTypes.number,
  }),
  currentHover: PropTypes.string.isRequired,
  handleHover: PropTypes.func.isRequired,
};
