import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import addThousandsSeparators from '../../helpers';
import { ReactComponent as CheckMark } from '../../assets/checkmark.svg';

const useStyles = makeStyles((theme) => ({
  li: {
    height: 51,
    padding: theme.spacing(0, 1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'start',
      padding: theme.spacing(0, 2),
      flexDirection: 'row',
      alignItems: 'center',
    },
    '&.odd': {
      background: 'rgb(249, 249, 249)',
    },
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
    '&.inactive': {
      opacity: 0.3,
    },
  },
  extraText: {
    fontStyle: 'italic',
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
    '&.inactive': {
      opacity: 0.3,
    },
  },
}));

function getValue(isCurrency, value) {
  if (isCurrency && value !== 'None') {
    return `$${addThousandsSeparators(value)}`;
  } if (value === true) {
    return <CheckMark />;
  }
  return value;
}

export default function CombinesDetails({ attributes, currentHover }) {
  const classes = useStyles();
  const hoverActive = currentHover !== '';

  return (
    <div>
      {attributes.map((attribute, index) => (
        <div className={[classes.li, index % 2 === 0 ? 'even' : 'odd'].join(' ')} key={attribute.key}>
          <Typography
            variant="body1"
            color="textPrimary"
            className={[classes.text, attribute.key !== currentHover && hoverActive ? 'inactive' : ''].join(' ')}
          >
            {getValue(attribute.is_currency, attribute.value)}
          </Typography>
          {attribute.extra_label && (
            <Typography
              variant="body1"
              color="textPrimary"
              className={[classes.extraText, attribute.key !== currentHover && hoverActive ? 'inactive' : ''].join(' ')}
            >
              {attribute.extra_label}
            </Typography>
          )}
        </div>
      ))}
    </div>
  );
}

CombinesDetails.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      () => null,
    ]),
    is_currency: PropTypes.bool,
  })).isRequired,
  currentHover: PropTypes.string.isRequired,
};
