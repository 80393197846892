import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import addThousandsSeparators from '../../helpers';

const useStyles = makeStyles((theme) => ({
  combineHeader: {
    padding: theme.spacing(2, 0, 2, 1),
    [theme.breakpoints.down('sm')]: {
      // padding: theme.spacing(2, 1),
      padding: ({ versionB }) => (versionB ? theme.spacing(2.5, 1.5, 0, 1.5) : theme.spacing(2, 0)),
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      padding: theme.spacing(2, 0),
    },
    cursor: 'default',
  },
  combineHeaderCurrencyChange: {
    padding: theme.spacing(2, 0, 2, 0),
  },
  combine: {
    height: 460,
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 0px 5px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
  },
  divider: {
    // width: 80,
    width: '100%',
    borderColor: theme.palette.secondary.main,
    borderWidth: 2,
    borderStyle: 'solid',
  },
  monthlyPriceContainer: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    borderRadius: '20px 0 0 20px',
    backgroundColor: theme.packages.container.background,
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      borderRadius: '20px 20px 0 0',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      flexDirection: 'column',
      borderRadius: '20px 20px 0 0',
    },
    flexWrap: 'wrap',
    color: theme.palette.primary.main,
  },
  monthlyPriceContainerCurrencyChange: {
    borderRadius: '20px 20px 0 0',
    display: 'block',
  },
  normalMonthlyPriceContainer: {
    width: '80%',
    margin: '10px 0 0 7px',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      margin: '9px 0 0 9px',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      flexDirection: 'column',
      margin: '11px 0 0 9px',
    },
    flexWrap: 'wrap',
    color: theme.palette.primary.main,
  },
  monthlyPrice: {
    flexGrow: 1,
    letterSpacing: '.18px',
    backgroundColor: theme.packages.price.background,
    borderRadius: '14px',
    textAlign: 'center',
    color: theme.packages.price.color,
    fontSize: '26px',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: ({ versionB }) => (versionB ? 24 : 20),
      flexGrow: 0,
      borderRadius: 0,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      fontSize: 20,
      flexGrow: 0,
      borderRadius: 0,
    },
  },
  monthlyPriceCurrency: {
    borderRadius: 0,
  },
  normalPrice: {
    flexGrow: 1,
    letterSpacing: '.18px',
    fontSize: '27px',
    [theme.breakpoints.down('xs')]: {
      fontSize: ({ versionB }) => (versionB ? 24 : 20),
      flexGrow: 0,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      fontSize: 18,
      flexGrow: 0,
    },
  },
  monthlyPriceDiscount: {
    fontSize: 16,
    textDecoration: 'line-through',
  },
  month: {
    flexWrap: 'wrap',
    letterSpacing: '.15px',
    placeSelf: 'center',
    fontSize: 14,
    lineHeight: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      placeSelf: 'auto',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      fontSize: 12,
      placeSelf: 'auto',
    },
  },
  monthNormal: {
    flexWrap: 'wrap',
    letterSpacing: '.15px',
    placeSelf: 'center',
    fontSize: 14,
    margin: 7,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      placeSelf: 'auto',
      padding: '15px 0',
      margin: 0,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      fontSize: 12,
      placeSelf: 'auto',
      padding: '16px 0',
      margin: 0,
    },
  },
  approxText: {
    fontSize: 10,
    margin: '0 10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      placeSelf: 'auto',
      margin: 0,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      fontSize: 10,
      placeSelf: 'auto',
      margin: 0,
    },
  },
  approxTextNormal: {
    margin: '1px 0',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      margin: 0,
    },
  },
  monthDiscount: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '1',
  },
  buyingPower: {
    fontSize: ({ versionB }) => (versionB ? 14 : 10),
  },
  buyingPowerContainer: {
    width: 'fit-content',
    margin: '0 16px',
  },
  monthPriceContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 10px',
  },
  accountSizeLabel: {
    margin: '0 16px',
    [theme.breakpoints.down('xs')]: {
      margin: '0 10px',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      margin: '0 10px',
    },
  },
}));

export default function CombinesHeader({
  monthly, total, assetClass, selectedCurrency, versionB, monthlyDiscount,
}) {
  const classes = useStyles({ assetClass, versionB });
  const packagePrice = selectedCurrency ? (monthly * selectedCurrency.rate).toFixed(0) : 0;
  const packagePriceDigits = (packagePrice.toString().length);
  return (
    <div className={[classes.combineHeader, packagePriceDigits > 3 ? classes.combineHeaderCurrencyChange : ''].join(' ')}>
      <Typography variant="h3" color="secondary" className={classes.accountSizeLabel}>
        {total}
      </Typography>

      <div className={classes.buyingPowerContainer}>
        <Typography variant="caption" color="textPrimary" className={classes.buyingPower}>BUYING POWER</Typography>
        <div className={classes.divider} />
      </div>

      <div className={monthlyDiscount ? (packagePriceDigits > 3 ? [classes.monthlyPriceContainer, classes.monthlyPriceContainerCurrencyChange].join(' ') : classes.monthlyPriceContainer) : classes.normalMonthlyPriceContainer}>
        {!monthlyDiscount && (
        <>
          <Typography variant="h5" color="inherit" className={classes.normalPrice} translate="no">
            {selectedCurrency !== null && selectedCurrency.currency !== 'USD' ? '~' : ''}
            {selectedCurrency !== null ? selectedCurrency.symbol : '$'}
            {selectedCurrency !== null ? addThousandsSeparators((monthly * selectedCurrency.rate).toFixed(0))
              : addThousandsSeparators((monthly).toFixed(0))}
          </Typography>
          <Typography variant="body1" color="inherit" className={classes.monthNormal}>
            <span translate="no">{selectedCurrency !== null ? selectedCurrency.currency : 'USD'}</span>
            /month
            {selectedCurrency !== null && selectedCurrency.currency !== 'USD' ? '*' : ''}
          </Typography>
        </>
        )}
        {monthlyDiscount && (
        <>
          <Typography variant="h5" color="inherit" className={packagePriceDigits > 3 ? [classes.monthlyPrice, classes.monthlyPriceCurrency].join(' ') : classes.monthlyPrice} translate="no">
            {selectedCurrency !== null && selectedCurrency.currency !== 'USD' ? '~' : ''}
            {selectedCurrency !== null ? selectedCurrency.symbol : '$'}
            {selectedCurrency !== null ? addThousandsSeparators(((monthlyDiscount || monthly) * selectedCurrency.rate).toFixed(0))
              : addThousandsSeparators((monthlyDiscount).toFixed(0))}
          </Typography>
          <div className={classes.monthPriceContainer}>
            <Typography variant="caption" color="inherit" className={classes.month}>
              <span translate="no">{selectedCurrency !== null ? selectedCurrency.currency : 'USD'}</span>
              /month
              {selectedCurrency !== null && selectedCurrency.currency !== 'USD' ? '*' : ''}
            </Typography>
            <Typography variant="caption" color="textPrimary" className={[classes.month, classes.monthDiscount].join(' ')}>
              REGULARLY
              {' '}
              {selectedCurrency !== null ? selectedCurrency.symbol : '$'}
              {selectedCurrency !== null ? addThousandsSeparators((monthly * selectedCurrency.rate).toFixed(0))
                : addThousandsSeparators((monthly).toFixed(0))}
            </Typography>
          </div>
        </>
        )}
      </div>

    </div>
  );
}

CombinesHeader.defaultProps = {
  selectedCurrency: null,
  versionB: false,
  monthlyDiscount: null,
};

CombinesHeader.propTypes = {
  monthly: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  total: PropTypes.string.isRequired,
  assetClass: PropTypes.string.isRequired,
  versionB: PropTypes.bool,
  selectedCurrency: PropTypes.shape({
    symbol: PropTypes.string,
    code: PropTypes.string,
    rate: PropTypes.number,
    currency: PropTypes.string,
  }),
  monthlyDiscount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};
