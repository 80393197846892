const local = {
  API_URL: 'http://localhost:3000/api/assets',
  FOREX_URL: 'http://127.0.0.1:8000/store/recommend_account_size',
  FUTURES_CTA_URL: 'http://localhost:3000',
  FOREX_CTA_URL: 'http://127.0.0.1:8000',
  FOREX_CMS_URL: 'https://cms-staging.topstepfx.com',
  FUTURES_CMS_URL: 'https://cms-staging.topsteptrader.com',
  OPTIMIZE_EXPERIMENT_ID: '_-IZc65rQYOQWTBL3wqkfA',
  STATUS_PAGE: 'https://www.topstep.com/status-update/',
  LAUNCHDARKLY: {
    CLIENT_SIDE_ID: '62fa9d33e21c23117b8b2c81',
    ENABLED: true,
  },
};

const stag = {
  API_URL: 'https://staging.topsteptrader.com/api/assets',
  FOREX_URL: 'https://staging.topstepfx.com/store/recommend_account_size',
  FUTURES_CTA_URL: 'https://staging.topsteptrader.com',
  FOREX_CTA_URL: 'https://staging.topstepfx.com',
  FOREX_CMS_URL: 'https://cms-staging.topstepfx.com',
  FUTURES_CMS_URL: 'https://cms-staging.topsteptrader.com',
  OPTIMIZE_EXPERIMENT_ID: '_-IZc65rQYOQWTBL3wqkfA',
  STATUS_PAGE: 'https://www.topstep.com/status-update/',
  LAUNCHDARKLY: {
    CLIENT_SIDE_ID: '62f28aa1f2d1af114592327e',
    ENABLED: true,
  },
};

const qa = {
  API_URL: 'https://qa.topsteptrader.com/api/assets',
  // There is no QA for FX at the moment
  FOREX_URL: 'https://staging.topstepfx.com/store/recommend_account_size',
  FUTURES_CTA_URL: 'https://qa.topsteptrader.com',
  FOREX_CTA_URL: 'https://staging.topstepfx.com',
  FOREX_CMS_URL: 'https://cms-staging.topstepfx.com',
  FUTURES_CMS_URL: 'https://cms-staging.topsteptrader.com',
  OPTIMIZE_EXPERIMENT_ID: '_-IZc65rQYOQWTBL3wqkfA',
  STATUS_PAGE: 'https://www.topstep.com/status-update/',
  LAUNCHDARKLY: {
    CLIENT_SIDE_ID: '62f28a583c1cba10bfd1a265',
    ENABLED: true,
  },
};

const qa2 = {
  API_URL: 'https://qa2.topsteptrader.com/api/assets',
  // There is no QA for FX at the moment
  FOREX_URL: 'https://staging.topstepfx.com/store/recommend_account_size',
  FUTURES_CTA_URL: 'https://qa2.topsteptrader.com',
  FOREX_CTA_URL: 'https://staging.topstepfx.com',
  FOREX_CMS_URL: 'https://cms-staging.topstepfx.com',
  FUTURES_CMS_URL: 'https://cms-staging.topsteptrader.com',
  OPTIMIZE_EXPERIMENT_ID: '_-IZc65rQYOQWTBL3wqkfA',
  STATUS_PAGE: 'https://www.topstep.com/status-update/',
  LAUNCHDARKLY: {
    CLIENT_SIDE_ID: '62f28a4c0f5a9a11daafe50f',
    ENABLED: true,
  },
};

const staging5 = {
  API_URL: 'https://staging5.topsteptrader.com/api/assets',
  // only one lower env for staging
  FOREX_URL: 'https://staging.topstepfx.com/store/recommend_account_size',
  FUTURES_CTA_URL: 'https://staging5.topsteptrader.com',
  FOREX_CTA_URL: 'https://staging.topstepfx.com',
  FOREX_CMS_URL: 'https://cms-staging.topstepfx.com',
  FUTURES_CMS_URL: 'https://cms-staging.topsteptrader.com',
  OPTIMIZE_EXPERIMENT_ID: '_-IZc65rQYOQWTBL3wqkfA',
  STATUS_PAGE: 'https://www.topstep.com/status-update/',
  LAUNCHDARKLY: {
    CLIENT_SIDE_ID: '62f28a2baf1a06117a0983cc',
    ENABLED: true,
  },
};

const prod = {
  API_URL: 'https://app.topsteptrader.com/api/assets',
  FOREX_URL: 'https://app.topstepfx.com/store/recommend_account_size',
  FUTURES_CTA_URL: 'https://app.topsteptrader.com',
  FOREX_CTA_URL: 'https://app.topstepfx.com',
  FOREX_CMS_URL: 'https://www.topstepfx.com/',
  FUTURES_CMS_URL: 'https://www.topsteptrader.com/',
  OPTIMIZE_EXPERIMENT_ID: 'dPxPsKQEROWO_COteWYspg',
  STATUS_PAGE: 'https://www.topstep.com/status-update/',
  LAUNCHDARKLY: {
    CLIENT_SIDE_ID: '62f27f5ffb29e21107643da4',
    ENABLED: true,
  },
};

const config = (((env) => {
  switch (env) {
    case 'production':
      return prod;
    case 'staging':
      return stag;
    case 'qa':
      return qa;
    case 'qa2':
      return qa2;
    case 'staging5':
      return staging5;
    default:
      return local;
  }
})(process.env.REACT_APP_ENV));

export default config;
