import React from 'react';
import './index.css';
import PropTypes from 'prop-types';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
  palette: {
    text: {
      primary: '#000',
      secondary: '#fff',
      forex: '#000',
      futures: '#fff',
    },
    primary: {
      main: '#000',
      futures: 'rgb(0, 162, 90)',
      forex: '#9FF3FF',
    },
    secondary: {
      main: '#487A7B',
      light: '#85f6ff',
    },
  },
  typography: {
    fontFamily: 'Source Sans Pro, sans-serif',
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 12,
    },
    caption: {
      fontSize: 10,
      letterSpacing: 1.5,
    },
    h3: {
      fontSize: 34,
    },
    button: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: 'none',
    },
  },
  popper: {
    background: 'rgb(39, 50, 62)',
  },
  packages: {
    price: {
      color: '#f7d636',
      background: '#050909',
    },
    container: {
      background: '#F8E49C',
    },
  },
});

theme.overrides = {
  ...theme.overrides,
  MuiSelect: {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: theme.typography.body1,
    },
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
        outline: '2px solid rgb(2, 94, 204)',
      },
    },
  },
  MuiMenuItem: {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: theme.typography.body1,
    },
  },
  MuiMenu: {
    paper: {
      marginTop: -16,
    },
  },
  MuiListItem: {
    button: {
      '&:hover': {
        backgroundColor: 'rgb(88, 151, 251);',
        color: '#fff !important',
      },
      '&:focus': {
        backgroundColor: 'rgb(88, 151, 251);',
        color: '#fff !important',
      },
    },
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.element.isRequired,
};

export { Theme, theme };
