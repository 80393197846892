import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import './index.css';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { tagManagerArgs } from './constants';
import { APPLICATION, DEFAULT_USER, CLIENT_SIDE_ID } from './utils/feature-flag-v2-utils';

TagManager.initialize(tagManagerArgs);

(async () => {
  console.log(`Feature Flag initializing ${CLIENT_SIDE_ID}.`);
  const LDProvider = await asyncWithLDProvider({
    clientSideID: CLIENT_SIDE_ID,
    user: DEFAULT_USER,
    options: {
      application: APPLICATION,
      streaming: true,
    },
  });

  ReactDOM.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
