import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import config from '../../config';
import { usePausePurchasesFlag } from '../../utils/feature-flag-v2-utils';

const useStyles = makeStyles((theme) => ({
  combineButton: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.down('xs')]: {
      padding: ({ versionB }) => (versionB ? theme.spacing(1.5, 1.5, 3.5, 1.5) : theme.spacing(1, 1)),
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      padding: theme.spacing(1, 1),
    },
  },
  button: {
    '&.active': {
      backgroundColor: 'rgb(50, 85, 86)',
    },
    '&:focus': {
      outline: '2px solid rgb(2, 94, 204)',
    },
  },
}));

export default function CombinesButton({
  assetClass, active, url, handleHoverCard, combine, versionB,
}) {
  const classes = useStyles({ assetClass, versionB });

  const urlPrefix = config.FUTURES_CTA_URL;

  const inputEl = useRef(null);

  const [clicked, setClicked] = useState(false);

  const pausePurchasesFlag = usePausePurchasesFlag();

  useEffect(
    () => {
      if (clicked) {
        inputEl.current.click();
      }
    },
    [clicked],
  );

  return (
    <div
      className={classes.combineButton}
    >
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        className={[classes.button, active ? 'active' : ''].join(' ')}
        id={`purchase-button-${combine}`}
        href={urlPrefix + url}
        disableTouchRipple
        disabled={pausePurchasesFlag}
        disableFocusRipple
        disableElevation
        ref={inputEl}
        onFocus={() => { handleHoverCard(combine); }}
        tabIndex={0}
        onBlur={() => { handleHoverCard(''); }}
        onKeyDown={(e) => {
          const code = (e.keyCode ? e.keyCode : e.which);
          if (code === 13) {
            setClicked(true);
          }
        }}
        onMouseDown={(e) => { e.preventDefault(); }}
      >
        { pausePurchasesFlag ? 'Temporarily On-Hold' : 'Select' }
      </Button>
    </div>
  );
}

CombinesButton.defaultProps = {
  versionB: false,
};

CombinesButton.propTypes = {
  assetClass: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  handleHoverCard: PropTypes.func.isRequired,
  combine: PropTypes.string.isRequired,
  versionB: PropTypes.bool,
};
