import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    paddingBottom: 10,
  },
  scrollStepperPlaceholder: {
    [theme.breakpoints.down('sm')]: {
      height: 26,
    },
  },
}));

export default function ScrollStepper({ dotsLength, position }) {
  const classes = useStyles();

  if (dotsLength <= 1) {
    return (
      <div className={classes.scrollStepperPlaceholder} />
    );
  }

  return (
    <MobileStepper
      variant="dots"
      steps={dotsLength}
      position="static"
      activeStep={position}
      className={classes.root}
      backButton={null}
      nextButton={null}
    />
  );
}

ScrollStepper.propTypes = {
  dotsLength: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
};
