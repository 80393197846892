import { useEffect, useState } from 'react';

const useContainerDimensions = (myRef, assetClass) => {
  const [dimensions, setDimensions] = useState({ widthChild: null, width: 0, widthParent: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      widthChild: myRef.current.firstChild.offsetWidth,
      width: myRef.current.offsetWidth,
      widthParent: myRef.current.parentElement.offsetWidth,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };
    window.addEventListener('resize', handleResize);

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef, assetClass]);

  return dimensions;
};

export default useContainerDimensions;
