import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import Cookies from 'js-cookie';
import ReactCountryFlag from 'react-country-flag';
import {
  Typography, Popper, Select, MenuItem, FormControl,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListItemText from '@material-ui/core/ListItemText';
import { DOMAINS, COUNTRIES } from '../../constants';

const useStyles = makeStyles((theme) => ({
  currencyContainer: {
    backgroundColor: '#fff',
    zIndex: 1000,
    height: 30,
    width: '100%',
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      '&>.MuiSelect-iconOutlined': {
        right: 0,
      },
      '&>.MuiSelect-root': {
        paddingLeft: 8,
      },
    },
  },
  popper: {
    background: theme.popper.background,
    borderRadius: '4px',
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.3)',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 'normal',
    letterSpacing: 0,
    lineHeight: 1.4,
    padding: 10,
    margin: 10,
  },
  pop: {
    zIndex: 9999,
    '&[x-placement*="left"]': {
      '& > #arrow': {
        right: 0,
        marginLeft: '-1em',
        '&::before': {
          borderWidth: '1em 0 1em 1em',
          borderColor: `transparent transparent transparent ${theme.popper.background}`,
        },
      },
    },
    '&[x-placement*="right"]': {
      '& > #arrow': {
        left: 0,
        marginRight: '-1em',
        '&::before': {
          borderWidth: '1em 1em 1em 0',
          borderColor: `transparent ${theme.popper.background} transparent transparent`,
        },
      },
    },
    '&[x-placement*="top"]': {
      '& > #arrow': {
        bottom: 0,
        marginBottom: '-1em',
        '&::before': {
          borderWidth: '1em 1em 0 1em',
          borderColor: `${theme.popper.background} transparent transparent transparent`,
        },
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',

    },
  },
  currencyMenuItem: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 35,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(2),
      height: 35,
    },
  },
  flag: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0.5),
    },
    paddingRight: theme.spacing(1),
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
  },
  countryText: {
    [theme.breakpoints.down('sm')]: {
      '&>.MuiTypography-body1': {
        fontSize: 13,
      },
    },
    flex: '0 1 auto',
  },
}));

export default function CurrencyConverter({ selectedCurrencyCallback, handleToggle, setCurrencyError }) {
  const classes = useStyles();
  const [chosenCurrency, setChosenCurrency] = useState(null);
  const [exchangeRates, setExchangeRates] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);
  const anchorRef = React.useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getDomain = () => {
    const property = window.location.hostname;

    switch (true) {
      case property.includes(DOMAINS.FUTURES_APP):
        return `.${DOMAINS.FUTURES_APP}`;
      case property.includes(DOMAINS.LOCAL):
        return `${DOMAINS.LOCAL}`;
      default:
        return '';
    }
  };

  const currencySelected = () => {
    // update chosenCurrency currency cookie and local storage anytime chosenCurrency changes
    if (chosenCurrency) {
      Cookies.set('currentCurrency', chosenCurrency, { expires: 30, domain: getDomain() });
      window.localStorage.setItem('tstCurrentCurrency', chosenCurrency);
      // when both exchange rates and chosen currency are set send it
      if (exchangeRates) {
        // create object with currency and rate
        const serializedCurrency = { ...COUNTRIES[chosenCurrency], rate: exchangeRates[chosenCurrency] };
        selectedCurrencyCallback(serializedCurrency);
      }
    }
  };

  const updateExchangeRates = () => {
    // if we haven't pulled exchange rate data, pull it
    const hasExchangeRates = Cookies.get('exchangeRates');
    if (hasExchangeRates) {
      setExchangeRates(JSON.parse(hasExchangeRates));
    } else if (!exchangeRates) {
      axios.get('https://openexchangerates.org/api/latest.json?app_id=8f047c638ee14233bf7482941eecf54d')
        .then((response) => {
          setExchangeRates(response.data.rates);
          Cookies.set('exchangeRates', response.data.rates, { expires: 0.05, domain: getDomain() });
        }).catch(() => {
          selectedCurrencyCallback(null);
          setCurrencyError(true);
        });
    }
  };

  const togglePopper = () => {
    setPopperOpen(!popperOpen);
  };
  // update if currency or exchange rates change
  useEffect(currencySelected, [chosenCurrency, exchangeRates]);
  // load exchange rates on mount
  useEffect(updateExchangeRates, []);
  //
  useEffect(() => {
    const currentCurrencyCookie = Cookies.get('currentCurrency');
    let currency = 'USD';

    if (currentCurrencyCookie) {
      currency = COUNTRIES[currentCurrencyCookie].currency;
    } else if (window.localStorage.getItem('tstCurrentCurrency')) {
      currency = window.localStorage.getItem('tstCurrentCurrency');
    }

    setChosenCurrency(currency);
  }, []);

  return (
    <>
      {!isMobile && (
        <Popper
          placement="top"
          className={classes.pop}
          disablePortal={false}
          anchorEl={anchorRef.current}
          open={popperOpen}
          modifiers={{
            flip: {
              enabled: true,
              options: {
                fallbackPlacements: ['left', 'right'],
              },
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          }}
        >
          <span className={classes.arrow} ref={setArrowRef} id="arrow" />
          <Typography className={classes.popper}>Local currency prices for display purposes only.</Typography>
        </Popper>
      )}

      <FormControl
        variant="outlined"
        className={classes.currencyContainer}
        ref={anchorRef}
      >
        <Select
          className={classes.currencyContainer}
          value={chosenCurrency || 'USD'}
          id="currency-converter"
          translate="no"
          onChange={(e) => setChosenCurrency(e.target.value)}
          MenuProps={{
            getContentAnchorEl: null,
            onEnter: togglePopper,
            onExit: togglePopper,
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          }}
          onOpen={() => {
            if (isMobile) {
              handleToggle();
            }
          }}
          onClose={() => {
            if (isMobile) {
              handleToggle();
            }
          }}
        >
          {
              Object.keys(COUNTRIES).map((symbol) => (
                <MenuItem
                  translate="no"
                  className={classes.currencyMenuItem}
                  key={COUNTRIES[symbol].code}
                  value={COUNTRIES[symbol].currency}
                >
                  <div className={classes.flag}>
                    <ReactCountryFlag countryCode={COUNTRIES[symbol].code} svg />
                  </div>
                  <ListItemText primary={COUNTRIES[symbol].currency} className={classes.countryText} />
                </MenuItem>
              ))
            }
        </Select>
      </FormControl>
    </>
  );
}

CurrencyConverter.defaultProps = {
  handleToggle: null,
};

CurrencyConverter.propTypes = {
  selectedCurrencyCallback: PropTypes.func.isRequired,
  setCurrencyError: PropTypes.func.isRequired,
  handleToggle: PropTypes.func,
};
