import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  tooltipText: {
    position: 'absolute',
    background: '#636363',
    width: '100vw',
    bottom: 0,
    left: 0,
    zIndex: 4,
    borderRadius: '6px 6px 0px 0px',
  },
  info: {
    padding: theme.spacing(3, 4.5, 3.5, 4.5),
  },
  label: {
    paddingBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: 'white',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function CombineLabelTooltipMobile({
  text, label, assetClass, open, handleClose,
}) {
  const classes = useStyles({ assetClass });

  if (!text) {
    return (<div />);
  }

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <div className={classes.tooltipText}>
          <CloseIcon
            color="inherit"
            className={classes.closeIcon}
            onClick={handleClose}
            fontSize="small"
          />
          <ClickAwayListener onClickAway={handleClose}>
            <div className={classes.info}>
              <Typography variant="body1" color="inherit" className={classes.label}>
                {label}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {text}
              </Typography>
            </div>
          </ClickAwayListener>
        </div>
      </Slide>
    </Backdrop>
  );
}

CombineLabelTooltipMobile.defaultProps = {
  text: null,
};

CombineLabelTooltipMobile.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string.isRequired,
  assetClass: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
